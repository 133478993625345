body {
  overflow-x: hidden;
  margin: 0 auto;
  font-family: 'Noto Sans TC', 'Microsoft JhengHei', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Source Code Pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', 'monospace';
}

*,
::after,
::before {
  box-sizing: border-box;
}
